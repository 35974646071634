<template>
 <div>
    <el-input ref="input" :size="size" readonly :value="defaultValue" v-if="readonly"/>
    <el-select
      class="w-100"
      :size="size"
      v-else
      v-model.trim="defaultValue"
      filterable
      remote
      :placeholder="palceholder"
      :remote-method="getList"
      @visible-change="handleVisibleChange"
      @change="hendleChange"
      :loading="loading">
      <el-option
        v-for="item in optionList"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
 </div>
</template>
<script>
import {queryBankPage} from '@/api/bank';
export default {
  name: 'BANK_SELECTOR',
  props: {
    value: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    palceholder: {
      type: String,
      default: '请选择银行',
    },
    releaseStatus: {
      type: [String, Object],
      default: null,
    },
  },
  data () {
    return {
      optionList: [],
      loading: false,
      defaultValue: this.name,
    };
  },
  watch: {
    name: function (val) {
      if (val !== this.defaultValue) {
        this.defaultValue = val;
      }
    },
  },
  methods: {
    getList (keyword = '') {
      this.loading = true;
      queryBankPage({
        name: keyword,
        pageNum: 1,
        pageSize: 10,
        enablePage: true,
      }).then(({body}) => {
        this.loading = false;
        this.optionList = body.list || [];
      }).catch((res) => {
        if (res?.heads?.code !== -100) {
          this.loading = false;
        }
      });
    },
    hendleChange (id) {
      let item = this.optionList.find(t => t.id === id);
      if (item) {
        this.$emit('input', item.id);
        this.$emit('update:name', item.name);
        this.$emit('change', item);
      }
    },
    handleVisibleChange (visible) {
      if (visible) {
        this.getList();
      }
    },
  },
  created () {
    this.getList();
  },
};

</script>
